import { onClickOutside } from '@vueuse/core';
import type { SelectOptionChildren } from '../../../options-list/types';
import type { UseInputAutocompleteOptions } from '../types';

export function useInputAutocomplete(options: UseInputAutocompleteOptions) {
  const { emit, props } = options;

  const inputModel = ref('');
  const isFocused = ref(false);
  const inputRef = ref<HTMLInputElement>();
  const containerRef = ref<HTMLInputElement>();

  const showOptions = computed(() => {
    if (props.isLoading) {
      return true;
    }
    return props.showDefaultOptions ? isFocused.value : inputModel.value.length > 0 && isFocused.value;
  });

  const setFocus = (value: boolean = true) => {
    if (value) {
      isFocused.value = value;
      inputRef.value?.focus();
      emit('onFocus');
    } else {
      inputRef.value?.blur();
      emit('onBlur');

      setTimeout(() => {
        isFocused.value = value;
      }, 200);
    }
  };

  function onOptionSelectAction(option: SelectOptionChildren) {
    emit('onOptionSelect', { option, input: inputModel.value });
    inputModel.value = option.label;
    setFocus(false);
  }

  function onInputAction() {
    if (!isFocused.value) {
      setFocus(true);
    }
    emit('onTyping', inputModel.value);
  }

  function trackOnClickOutside() {
    onClickOutside(containerRef, () => {
      if (isFocused.value) {
        setFocus(false);
      }
    });
  }

  onMounted(() => {
    trackOnClickOutside();
  });

  return {
    inputModel,
    isFocused,
    inputRef,
    containerRef,
    showOptions,
    setFocus,
    onOptionSelectAction,
    onInputAction,
  };
}
